import classNames from "classnames";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

import linkStyles from "../editorials/editorial-link/editorial-link.module.scss";
import styles from "./button.module.scss";
import Icon from "./icon";

export type ServiceButtonVariants = {
  variant?: "serviceBig" | "serviceMedium" | "serviceSmall";
};

export type ButtonVariants = {
  variant?: "primary" | "secondary";
};

export type ButtonProps = {
  lookAsLink?: boolean;
  loading?: boolean;
  variant?: ServiceButtonVariants["variant"] | ButtonVariants["variant"];
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const itemVariants: Variants = {
  open: {
    y: [0, -5, 5, 0],
    transition: {
      repeat: Infinity,
      repeatDelay: 0.5,
    },
  },
};

export default function Button(props: ButtonProps) {
  const { variant, lookAsLink, loading, ...rest } = props;

  function calculateClasses() {
    if (lookAsLink) {
      return [linkStyles.link, linkStyles.linkMedium];
    } else if (variant) {
      return [styles.button, styles[variant]];
    } else {
      return [];
    }
  }

  return (
    <button
      {...rest}
      type={props.type ?? "button"}
      data-type={props.variant}
      className={classNames(...calculateClasses(), { [styles.loading]: loading }, props.className)}
    >
      <AnimatePresence>
        {loading && (
          <motion.div
            className={styles.loadingWrapper}
            variants={{
              open: {
                opacity: 1,
              },
              closed: {
                opacity: 0,
              },
            }}
            transition={{ staggerChildren: 0.1 }}
            initial="closed"
            animate="open"
            exit="closed"
          >
            <motion.div className={styles.loadingDots} variants={itemVariants} />
            <motion.div className={styles.loadingDots} variants={itemVariants} />
            <motion.div className={styles.loadingDots} variants={itemVariants} />
          </motion.div>
        )}
      </AnimatePresence>
      {typeof props.children === "string" ? <span>{props.children}</span> : props.children}
      {(variant == "serviceBig" || variant == "serviceMedium") && (
        <div className={styles.iconWrapper}>
          <Icon name="arrow-right" />
        </div>
      )}
    </button>
  );
}
