import classNames from "classnames";
import { Url } from "next/dist/shared/lib/router/router";
import Link from "next/link";
import { AnchorHTMLAttributes } from "react";

import styles from "../editorials/editorial-link/editorial-link.module.scss";
import { ButtonVariants } from "./button";
import buttonStyles from "./button.module.scss";

type Props = AnchorHTMLAttributes<HTMLAnchorElement> & {
  href: Url;
  isSmall?: boolean;
  isFullWidth?: boolean;
  lookAsButton?: ButtonVariants["variant"];
  prefetch?: boolean;
  disabled?: boolean;
};

export default function LinkWrapper(props: Props) {
  // by default variant is medium
  const { href, isSmall, lookAsButton, isFullWidth, children, prefetch, disabled = false, ...rest } = props;

  function calculateClasses() {
    if (lookAsButton) {
      return [buttonStyles.button, buttonStyles[lookAsButton], buttonStyles.linkButton];
    } else {
      return [styles.link, isSmall ? styles.linkSmall : styles.linkMedium];
    }
  }

  return (
    <Link
      {...rest}
      href={!disabled ? href : ""}
      data-type={props.lookAsButton}
      className={classNames(
        ...calculateClasses(),
        { [buttonStyles.fullWidth]: isFullWidth, [buttonStyles.disabled]: disabled },
        props.className
      )}
      prefetch={prefetch}
    >
      {lookAsButton ? <span>{children}</span> : children}
    </Link>
  );
}
