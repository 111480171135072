import React, { createContext, ReactNode, useContext } from "react";

type ServerSidePropsContextType = {
  serverSideProps: any;
};

const ServerSidePropsContext = createContext<ServerSidePropsContextType | undefined>(undefined);

export const useServerSideProps = () => {
  const context = useContext(ServerSidePropsContext);
  if (context === undefined) {
    throw new Error("useServerSideProps must be used within a ServerSidePropsProvider");
  }
  return context;
};

type ServerSidePropsProviderProps = {
  children: ReactNode;
  serverSideProps: any; // Replace 'any' with your actual prop type
};

export const ServerSidePropsProvider: React.FC<ServerSidePropsProviderProps> = ({ children, serverSideProps }) => {
  return <ServerSidePropsContext.Provider value={{ serverSideProps }}>{children}</ServerSidePropsContext.Provider>;
};
