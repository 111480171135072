import CommonUtils from "~/utils/common-utils";

type StorageStrategies = "localStorage" | "sessionStorage";

export class StorageFactory<U extends string = string> {
  constructor(protected _mode: StorageStrategies) {}

  get<T>(key: U, autoParse: true): T | null;
  get(key: U, autoParse?: boolean): typeof autoParse extends true ? any : string | null;
  get<T = any>(key: U, autoParse?: boolean): T | string | null {
    if (typeof window === "undefined") {
      return null;
    }
    const value = window[this._mode].getItem(key);

    return value !== null && autoParse ? (CommonUtils.parseJsonSafely(value) as T) : value;
  }

  pop<T = any>(key: U, autoParse: true): T | null;
  pop(key: U, autoParse: boolean): typeof autoParse extends true ? any : string | null;
  pop(key: U): string | null;
  pop(key: U, autoParse: false): string | null;
  pop<T = any>(key: U, autoParse?: boolean): T | string | null {
    const value = this.get(key, autoParse);
    this.remove(key);

    return value;
  }

  remove(key: U): void {
    if (typeof window === "undefined") {
      return;
    }
    window[this._mode].removeItem(key);
  }

  set(key: U, rawValue: any): void {
    if (typeof window === "undefined") {
      return;
    }
    const value = typeof rawValue !== "string" ? JSON.stringify(rawValue) : rawValue;

    window[this._mode].setItem(key, value);
  }
}
