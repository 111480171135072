const objectUtils = {
  /**
   * Splits keys containing dots into nested objects.
   *
   * Example:
   * ```ts
   * const input = { "a.b.c": "first", "a.b.d": "second" }
   * const output = { a: { b: { c: "first", d: "second" } } }
   * ```
   *
   * @param record - The flat record
   */
  nestDottedKeys(record: Record<string, string>) {
    type Output = { [key: string]: string | Output };

    if (!record || Object.keys(record).length === 0) {
      return {};
    }

    const output: Output = {};

    for (const [key, value] of Object.entries(record)) {
      const path = key.split(".");
      let current = output;

      for (let i = 0; i < path.length - 1; i++) {
        const segment = path[i];

        if (!(segment in current)) {
          current[segment] = {};
        } else if (typeof current[segment] === "string") {
          current[segment] = { [path[i + 1]]: current[segment] };
        }

        current = current[segment] as Output;
      }

      current[path[path.length - 1]] = value;
    }

    return output;
  },

  /**
   * Checks if two objects are deeply equal by comparing their properties recursively.
   *
   * @param {any} obj1 - The first object to compare.
   * @param {any} obj2 - The second object to compare.
   * @returns {boolean} A boolean indicating whether the objects are deeply equal.
   */
  deepEqual(obj1: any, obj2: any): boolean {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];
      const areObjects = this.isObject(val1) && this.isObject(val2);
      if ((areObjects && !this.deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
        return false;
      }
    }

    return true;
  },

  /**
   * Checks if a value is an object.
   *
   * @param {any} obj - The value to check.
   * @returns {boolean} A boolean indicating whether the value is an object.
   */
  isObject(obj: any): boolean {
    return obj != null && typeof obj === "object";
  },
};

export default objectUtils;
