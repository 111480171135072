import { useEffect, useState } from "react";

const SCROLL_UP = "up";
const SCROLL_DOWN = "down";

type scrollDirection = "up" | "down";

interface scrollDirectionParams {
  initialDirection?: scrollDirection;
  thresholdPixels?: number;
}

const useScrollDirection = ({
  initialDirection = SCROLL_DOWN,
  thresholdPixels = 0,
}: scrollDirectionParams): { dir: scrollDirection; y: number } => {
  const [scrollDir, setScrollDir] = useState(initialDirection);
  const [scrolledY, setScrolledY] = useState(0);

  useEffect(() => {
    const threshold = thresholdPixels;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const isHidden = document.documentElement.style.getPropertyValue("overflow") == "hidden";

      if (!isHidden) {
        const scrollY = window.pageYOffset;

        if (Math.abs(scrollY - lastScrollY) < threshold) {
          // We haven't exceeded the threshold
          ticking = false;
          return;
        }

        setScrollDir(scrollY > lastScrollY ? SCROLL_DOWN : SCROLL_UP);
        lastScrollY = scrollY > 0 ? scrollY : 0;
        setScrolledY(lastScrollY);
      }
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [initialDirection, thresholdPixels]);

  return { dir: scrollDir, y: scrolledY };
};

export default useScrollDirection;
