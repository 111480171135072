import { Entry } from "contentful";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";

import Editorials from "~/types/editorials";
import { assertEditorialType, isActiveEntry } from "~/utils/editorial-utils";
import textUtils from "~/utils/text-utils";

import Icon from "../common/icon";
import MediaAsset from "../common/media-asset";
import styles from "./mini-footer.module.scss";

type Props = {
  entry: Entry<unknown>;
};

export default function MiniFooter(props: Props) {
  assertEditorialType<Editorials.MiniFooter>(props.entry, "footer");
  const { socialLinks, appleAppStoreLink, googlePlayStoreLink } = props.entry.fields;
  const router = useRouter();
  const t = useTranslations();

  return (
    <footer className={styles.footer}>
      <div className={styles.socialLinksContainer}>
        <span className={styles.followUsOnLabel}>{t("generic.follow_us_on")}</span>
        {socialLinks?.filter(isActiveEntry).map((socialLink, i) => {
          const { icon, openOnANewTab, text } = socialLink.fields;
          if (!isActiveEntry(icon!)) return null;

          const { svgIconId, svgMedia } = icon?.fields!;
          return (
            <Link
              prefetch={false}
              key={`${socialLink.sys.id}-mini-footer-${i}`}
              href={textUtils.sanitizeContentfulUrl(socialLink, router)}
              target={openOnANewTab ? "_blank" : undefined}
            >
              <Icon svgMedia={svgMedia} name={svgIconId} />
            </Link>
          );
        })}
      </div>
      {appleAppStoreLink || googlePlayStoreLink ? (
        <div className={styles.media}>
          {appleAppStoreLink && appleAppStoreLink.fields.image && isActiveEntry(appleAppStoreLink) ? (
            <div className={styles.mediaApple}>
              <Link
                prefetch={false}
                href={textUtils.sanitizeContentfulUrl(appleAppStoreLink, router)}
                target={appleAppStoreLink.fields.openOnANewTab ? "_blank" : undefined}
              >
                <MediaAsset entry={appleAppStoreLink.fields.image} />
              </Link>
            </div>
          ) : null}
          {googlePlayStoreLink && googlePlayStoreLink.fields.image && isActiveEntry(googlePlayStoreLink) ? (
            <div className={styles.mediaGoogle}>
              <Link
                prefetch={false}
                href={textUtils.sanitizeContentfulUrl(googlePlayStoreLink, router)}
                target={googlePlayStoreLink.fields.openOnANewTab ? "_blank" : undefined}
              >
                <MediaAsset entry={googlePlayStoreLink.fields.image} />
              </Link>
            </div>
          ) : null}
        </div>
      ) : null}
      <div className={styles.legalContainer}>
        <p className={styles.legalText}>{t("generic.footer_legal_text")}</p>
      </div>
    </footer>
  );
}
