import DidomiReact, { DidomiSDK, IDidomiConfig } from "@didomi/react";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import { useEffect, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { openedDidomiModalState } from "~/atoms/didomiModal";
import { sequentialLoadingState } from "~/atoms/sequentialLoading";
import { useObjectConfigurationValue } from "~/contexts/configuration";
import { useConsents } from "~/contexts/userconsents";
import crossCountryUtils from "~/utils/crossCountry-utils";

declare global {
  var dataLayer: any;
  var didomiGAId: string;
  var didomiOnReady: any;
  var didomiEventListeners: any;
}

export default function DidomiIntegration() {
  const consents = useConsents();
  const router = useRouter();
  const t = useTranslations();
  const currentLanguage = crossCountryUtils.getCurrentLanguageCode(router);

  const noticeIdConfig = useObjectConfigurationValue("App:Ecommerce:DidomiConfigs");

  // Recoil state that tells if the Didomi popup is currently opened or not
  const setDidomiOpened = useSetRecoilState<boolean>(openedDidomiModalState);
  const didomiBannerIsOpen = useRecoilValue<boolean>(openedDidomiModalState);

  // Recoil state that tells if the Didomi is loaded
  const setDidomiLoaded = useSetRecoilState(sequentialLoadingState);

  useEffect(() => {
    const didomiHost = document.getElementById("didomi-host");
    if (didomiHost) {
      const hasOverlay = noticeIdConfig?.hasOverlay ?? true;
      didomiHost.setAttribute("data-overlay", hasOverlay ? "true" : "false");
      didomiHost.style.height = didomiBannerIsOpen && hasOverlay ? "100%" : "0";

      if (noticeIdConfig?.firstButtonColor) {
        didomiHost.setAttribute("data-first-button-color", noticeIdConfig.firstButtonColor);
      }
      if (noticeIdConfig?.secondButtonColor) {
        didomiHost.setAttribute("data-second-button-color", noticeIdConfig.secondButtonColor);
      }

      if (noticeIdConfig?.setButtonsInColumn?.mobile) {
        didomiHost.setAttribute("data-buttons-in-column-mobile", "true");
      }
      if (noticeIdConfig?.setButtonsInColumn?.desktop) {
        didomiHost.setAttribute("data-buttons-in-column-desktop", "true");
      }

      const didomiNoticeXButton = document.getElementById("didomi-notice-x-button");
      if (didomiNoticeXButton) {
        didomiNoticeXButton.innerHTML = `<p>${t("generic.continueWithoutAccepting")}</p>`;
      }
    }
  }, [didomiBannerIsOpen, noticeIdConfig, t]);

  const didomiConfig: IDidomiConfig = useMemo(() => {
    return {
      theme: {
        font: "var(--local-font)",
        color: "rgb(37, 43, 46)",
        linkColor: "rgb(37, 43, 46)",
        buttons: {
          regularButtons: {
            borderWidth: "0",
            textColor: "rgb(37, 43, 46)",
            borderRadius: "8px",
          },
          highlightButtons: {
            borderWidth: "0",
            textColor: "rgba(255, 255, 255, 1)",
            borderRadius: "8px",
          },
        },
      },
      languages: {
        enabled: [currentLanguage], // multiple choices are not expected by design
        default: currentLanguage,
      },
      tagManager: {
        provider: "gtm",
      },
      user: {
        bots: {
          consentRequired: false,
          types: ["crawlers", "performance"],
          extraUserAgents: [],
        },
      },
    };
  }, [currentLanguage]);

  //To avoid overriding Didomi in case it's loaded before dataLayer initialization,
  useEffect(() => {
    window.dataLayer = window.dataLayer ?? [];
    window.didomiGAId = window.didomiGAId ?? process.env.NEXT_PUBLIC_PRIVACY_VENDOR_GAID;
  }, []);

  return (
    <DidomiSDK
      apiKey={process.env.NEXT_PUBLIC_DIDOMI_API_KEY}
      noticeId={noticeIdConfig?.bannerId}
      iabVersion={2}
      config={didomiConfig}
      gdprAppliesGlobally={true}
      onReady={(didomi) => {
        const observeAndToggleVendor = (vendorId: DidomiReact.ConsentID) => {
          didomi
            .getObservableOnUserConsentStatusForVendor(vendorId)
            .filter(Boolean) // Filter out updates where status is not true
            .first() // Only get the first consent status update
            .subscribe((consentStatusForVendor: any) => {
              // The user has given consent to the vendor
              // Enable it
              if (!!consentStatusForVendor) {
                // The vendor has consent
                checkConsents();
              }
            });
        };
        checkConsents();
        const vendorsWithKnownConsentStatus = Object.values(didomi.getUserStatus().vendors.consent).flat();
        if (!vendorsWithKnownConsentStatus.includes(window.didomiGAId)) {
          // The consent status of the user is not known yet
          // Subscribe to the consent.changed event to get notified when it does
          didomi.on("consent.changed", function () {
            // The consent status of the user has changed
            // Repeat checks and update cookies
            checkConsents();
          });
        }
        if (didomi.isConsentRequired()) {
          observeAndToggleVendor(window.didomiGAId);
        }
        // get vendor or purpose value of given id, if exists
        function getContainerValue(container: any, id: string) {
          const value = container.consent.enabled.includes(id);
          if (!value && !container.consent.disabled.includes(id)) {
            return;
          }
          return value;
        }
        function syncVendor(id: string) {
          const value = getContainerValue(didomi.getUserStatus().vendors, id);
          if (value !== undefined) {
            consents.updateVendor(id, value);
          }
        }
        function syncPurpose(id: string) {
          const value = getContainerValue(didomi.getUserStatus().purposes, id);
          if (value !== undefined) {
            consents.updatePurpose(id, value);
          }
        }
        function checkConsents() {
          syncVendor(window.didomiGAId);
          syncPurpose("analytics");
          syncPurpose("profiling");
        }
        //Didomi | GTM Integration
        /*
         * Push the Do Not Sell status to the dataLayer.
         */
        function evaluateConsentForCCPA() {
          window.dataLayer = window.dataLayer || [];
          /*
           * Apply it only to CCPA regulation.
           */
          if (didomi.isRegulationApplied("ccpa")) {
            /*
             * Do Not Sell status is set to false.
             * User has not explicitly denied consent.
             */
            if (!didomi.CCPA.getDoNotSellStatus()) {
              dataLayer.push({
                event: "didomi-consent",
                ccpaStatus: "false",
              });
              /*
               * Do Not Sell status is set to true.
               * User is now opted out.
               */
            } else {
              dataLayer.push({
                event: "didomi-consent",
                ccpaStatus: "true",
              });
            }
          }
        }
        /*
         * Don't forget to call the function on consent changed
         * and on Didomi SDK ready with the code below
         */
        /*
         * Update CCPA status on consent.changed
         */
        window.didomiEventListeners = window.didomiEventListeners || [];
        window.didomiEventListeners.push({
          event: "consent.changed",
          listener: function () {
            evaluateConsentForCCPA();
            checkConsents();
          },
        });
        /*
         * Push CCPA status to the dataLayer on didomiOnReady
         */
        window.didomiOnReady = window.didomiOnReady || [];
        window.didomiOnReady.push(function () {
          evaluateConsentForCCPA();
        });

        setDidomiLoaded((current) => ({ ...current, didomi: true }));
      }}
      onNoticeShown={() => setDidomiOpened(true)}
      onNoticeHidden={() => setDidomiOpened(false)}
    />
  );
}
