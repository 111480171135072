import classNames from "classnames";
import { AnimationProps } from "framer-motion";
import { useTranslations } from "next-intl";
import { Fragment, isValidElement, ReactNode, useEffect, useRef } from "react";
import ReactModal from "react-modal";

import styles from "./dialog.module.scss";
import Icon from "./icon";

type CustomStyles = {
  container?: string;
  backdrop?: string;
  overlay?: string;
  wrapper?: string;
  panel?: string;
  top?: string;
  header?: string;
  title?: string;
  description?: string;
  close?: string;
  content?: string;
};

export type Props = {
  isOpen?: boolean;
  showBackdrop?: boolean;
  showClose?: boolean;
  title?: string | ReactNode;
  description?: string | ReactNode;
  animationBackdrop?: AnimationProps;
  animationPanel?: AnimationProps;
  onAfterOpen?: () => void;
  onAfterClose?: () => void;
  onClose: () => void;
  customStyles?: CustomStyles;
  shouldReturnFocusAfterClose?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  shouldFocusAfterRender?: boolean;
  children: ReactNode;
};

export default function Dialog({
  title,
  description,
  isOpen = false,
  showBackdrop = true,
  showClose = true,
  onAfterOpen,
  onAfterClose,
  onClose,
  customStyles,
  shouldReturnFocusAfterClose = true,
  shouldCloseOnOverlayClick = true,
  shouldFocusAfterRender = true,
  children = <Fragment />,
}: Props) {
  const closeButtonRef = useRef(null);
  const t = useTranslations();

  const CustomTitle = isValidElement(title) ? "div" : "h2";
  const CustomDesc = isValidElement(description) ? "div" : "p";

  // remove styles on component unmount
  useEffect(() => {
    return () => {
      document.body.classList.remove("ReactModal__Body--open");
    };
  }, []);

  const dialogContent = (
    <div className={classNames(styles.panel, customStyles?.panel)}>
      <div className={classNames(styles.top, customStyles?.top)}>
        {title ? (
          <div className={classNames(styles.header, customStyles?.header)}>
            <CustomTitle className={classNames(styles.title, customStyles?.title)}>{title}</CustomTitle>
          </div>
        ) : null}
        {description ? (
          <div className={classNames(styles.header, customStyles?.header)}>
            <CustomDesc className={classNames(styles.description, customStyles?.description)}>{description}</CustomDesc>
          </div>
        ) : null}
        {showClose ? (
          <button
            ref={closeButtonRef}
            className={classNames(styles.close, customStyles?.close)}
            onClick={onClose}
            aria-label={t("generic.close")}
          >
            <Icon name="close" width={20} height={20} />
          </button>
        ) : null}
      </div>
      {children ? <div className={classNames(styles.content, customStyles?.content)}>{children}</div> : null}
    </div>
  );

  // in order to reduce the flickering effect on modal close mobile due to performance issues
  return (
    <ReactModal
      appElement={typeof window !== "undefined" ? (document.getElementById("__next") as HTMLElement) : undefined}
      overlayClassName={classNames(styles.overlay, customStyles?.overlay, { [styles.hasBackdrop]: showBackdrop })}
      className={classNames(styles.wrapper, customStyles?.wrapper)}
      isOpen={isOpen}
      onRequestClose={onClose}
      onAfterClose={() => {
        if (onAfterClose) {
          onAfterClose();
        }
        document.body.classList.remove("ReactModal__Body--open");
      }}
      onAfterOpen={() => {
        if (onAfterOpen) {
          onAfterOpen();
        }
      }}
      closeTimeoutMS={600}
      shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldFocusAfterRender={shouldFocusAfterRender}
    >
      {dialogContent}
    </ReactModal>
  );
}
