import classNames from "classnames";
import { DetailedHTMLProps, forwardRef, HTMLAttributes } from "react";

import styles from "./container.module.scss";

type Props = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default forwardRef<HTMLDivElement, Props>(function Container(props, ref) {
  return <div ref={ref} {...props} className={classNames(styles.container, props.className, "containerComponent")} />;
});
