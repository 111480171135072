"use client";

import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

import CommonUtils from "~/utils/common-utils";

import packageJson from "../../../package.json";

const datadogSharedConfig = {
  applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID!,
  env: process.env.NEXT_PUBLIC_DATADOG_ENV!,
  service: process.env.NEXT_PUBLIC_DATADOG_SERVICE!,
  site: "datadoghq.eu",
  version: packageJson.version,
};

const datadogRumConfig = {
  ...datadogSharedConfig,
  clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN!,
  sessionSampleRate: +process.env.NEXT_PUBLIC_DATADOG_RUM_SAMPLE_RATE!,
};

const datadogLogsConfig = {
  ...datadogSharedConfig,
  clientToken: process.env.NEXT_PUBLIC_DATADOG_LOGS_CLIENT_TOKEN!,
  sessionSampleRate: +(process.env.NEXT_PUBLIC_DATADOG_LOGS_SAMPLE_RATE! || 10),
};

if (CommonUtils.parseBoolean(process.env.NEXT_PUBLIC_DATADOG_ENABLED)) {
  if (Object.values(datadogRumConfig).every(Boolean)) {
    datadogRum.init({
      ...datadogRumConfig,
      sessionReplaySampleRate: +(process.env.NEXT_PUBLIC_DATADOG_REPLAY_SAMPLE_RATE! || 10),
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "allow",
    });
  }

  if (Object.values(datadogLogsConfig).every(Boolean)) {
    datadogLogs.init({
      ...datadogLogsConfig,
      forwardErrorsToLogs: !0,
      forwardConsoleLogs: ["error", "warn"],
    });
  }
}

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
