// NEXT STUFF - START
const nextLocales = {
  main: [
    "en-us",
    "it-it",
    "fr-fr",
    "de-de",
    "en-gb",
    "nl-nl",
    "de-ch",
    "it-ch",
    "fr-ch",
    "es-es",
    "fr-be",
    "nl-be",
    "pt-pt",
    "pl-pl",
    "en-ie",
    "de-at",
  ],
  middleEast: [
    "ar-ae",
    "en-ae",
    "ar-bh",
    "en-bh",
    "ar-jo",
    "en-jo",
    "ar-kw",
    "en-kw",
    "ar-om",
    "en-om",
    "ar-qa",
    "en-qa",
  ],
  multiCountry: [
    "en-bg",
    "en-cz",
    "en-dk",
    "en-ee",
    "en-fi",
    "en-hr",
    "en-hu",
    "en-lt",
    "en-lu",
    "en-lv",
    "en-ro",
    "en-no",
    "en-se",
  ],
};
// NEXT STUFF - END
// CONTENTFUL STUFF - START
const contentfulLocales = {
  /**
   * Locales with specific locales on Contentful
   */
  ...nextLocales.main.reduce((acc, nextLocale) => {
    const parts = nextLocale.split("-");
    acc[nextLocale] = `${parts[0]}-${parts[1].toUpperCase()}`;

    return acc;
  }, {}),
  /**
   * Countries related to the United Arab Emirates group
   *
   * {@link https://kikocosmetics.atlassian.net/browse/ECO-292}
   */
  ...{
    // First create the standard locales
    ...nextLocales.middleEast.reduce((acc, nextLocale) => {
      const parts = nextLocale.split("-");
      acc[nextLocale] = `${parts[0]}-${parts[1].toUpperCase()}`;
      return acc;
    }, {}),
    // Overwrite the ones that use the AE locale
    "ar-bh": "ar-AE",
    "en-bh": "en-AE",
    "ar-om": "ar-AE",
    "en-om": "en-AE",
    "ar-qa": "ar-AE",
    "en-qa": "en-AE",
  },
  /**
   * Countries related to the multi-country group, which on Contentful are managed with a unique locale
   *
   * {@link https://kikocosmetics.atlassian.net/browse/ECO-303}
   */
  ...nextLocales.multiCountry.reduce((acc, nextLocale) => {
    acc[nextLocale] = "en-MULTI";
    return acc;
  }, {}),
};
// CONTENTFUL STUFF - END

module.exports = {
  contentfulLocales,
  nextLocales: [...nextLocales.main, ...nextLocales.middleEast, ...nextLocales.multiCountry, "default"],
};
