import { Fragment, useMemo } from "react";

import { TextStyle } from "~/enums";
import textUtils from "~/utils/text-utils";

import EscapeNewLine from "./escape-new-line";

type Props = {
  text: string;
};

export default function HighlightedText(props: Props) {
  const tokenizedText = useMemo(() => textUtils.getStyledTextTokens(props.text ?? ""), [props.text]);
  return (
    <>
      {tokenizedText.map((el, i) => {
        if (el.style == TextStyle.DEFAULT) {
          return (
            <span
              key={`${el.token}-default-${i}`}
              dangerouslySetInnerHTML={{ __html: el.token.replaceAll("\\n", "<br />") }}
            />
          );
        } else if (el.style == TextStyle.HIGHLIGHTED) {
          return <EscapeNewLine key={`${el.token}-highlighted-${i}`} tag={"strong"} text={el.token} />;
        }
      })}
    </>
  );
}
