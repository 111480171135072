import type { ImageLoaderProps } from "next/image";

import CommonUtils from "./common-utils";
import Constants from "./constants";

const EVA_ASSET_PATTERN = /^https:\/\/assets.([a-z0-9]+).on-eva.io\/blob\/([^/]*)$/i;

const imageUtils = {
  /**
   * Loader that can be used in instances of `next/image` to optimize images in EVA instead of Vercel.
   *
   * @see https://nextjs.org/docs/api-reference/next.config.js/custom-image-loader-config
   * @see https://docs.newblack.io/documentation/documentation/other/images#images
   */
  evaImageLoader(props: ImageLoaderProps) {
    const match = props.src.match(EVA_ASSET_PATTERN);

    if (!match) {
      console.warn(`Could not optimize blob URL: ${props.src}`);
      return props.src;
    }

    return `https://assets.${match[1]}.on-eva.io/image/${props.width}/0/${match[2]}.webp`;
  },

  /**
   * Loader that can be used in instances of `next/image` to optimize images in Contentful instead of Vercel.
   *
   * @see https://nextjs.org/docs/api-reference/next.config.js/custom-image-loader-config
   * @see https://www.contentful.com/developers/docs/references/images-api
   */
  contentfulImageLoader(props: ImageLoaderProps) {
    let src = props.src;

    if (CommonUtils.parseBoolean(process.env.NEXT_PUBLIC_ENABLE_CLOUDFRONT_ASSETS)) {
      src = src.replace(Constants.ASSETS_CDN_IMAGES_SOURCE.CONTENTFUL, Constants.ASSETS_CDN_IMAGES_SOURCE.CLOUDFRONT);
      src = src.replace(Constants.ASSETS_CDN_VIDEOS_SOURCE.CONTENTFUL, Constants.ASSETS_CDN_VIDEOS_SOURCE.CLOUDFRONT);
    }

    // avoid image conversion to WEBP when SVGs
    if (src.endsWith(".svg")) {
      return src;
    } else {
      return `${src}?w=${props.width}&fm=webp`;
    }
  },
  transparentPixel:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=",
};

export default imageUtils;
