const algoliaUtils = {
  /**
   * Maps a Next.js/Vercel locale to the corresponding Algolia locale.
   *
   * @param locale - The locale for the current request
   */
  mapLocale(organizationUnitId: number, locale: string | undefined) {
    if (!locale || !locale.includes("-")) {
      console.error(`Invalid locale ${locale} found in request, this will likely error out.`);
      return `${organizationUnitId}_${locale}`;
    }

    const [language, country] = locale.split("-");

    return `${organizationUnitId}_${language}-${country.toUpperCase()}`;
  },
};

export default algoliaUtils;
