/**
 * A utility module for working with loyalty subscriptions.
 * @module LoyaltyUtils
 */

import Constants from "./constants";
import evaUtils from "./eva-utils";

const loyaltyUtils = {
  /**
   * Retrieves the loyalty subscription from a list of user subscriptions.
   *
   * @param {EVA.Core.GetUserSubscriptionsResponse.UserSubscriptionDto[] | undefined} subscriptions -
   *   The list of user subscriptions to search within.
   *
   * @returns {EVA.Core.GetUserSubscriptionsResponse.UserSubscriptionDto | undefined} The loyalty subscription,
   *   if found; otherwise, undefined.
   */
  getLoyaltySubscription(
    subscriptions: EVA.Core.GetUserSubscriptionsResponse.UserSubscriptionDto[] | undefined
  ): EVA.Core.GetUserSubscriptionsResponse.UserSubscriptionDto | undefined {
    return (
      (subscriptions instanceof Array &&
        subscriptions?.find((sub) => sub.Subscription?.BackendID === Constants.LOYALTY_PROGRAM_BACKEND_ID)) ||
      undefined
    );
  },

  /**
   * Retrieves the Kiko loyalty program from a list of available subscriptions.
   *
   * @param {EVA.Core.GetAvailableSubscriptionsResponse.SubscriptionDto[] | undefined} subscriptions -
   *   The list of user subscriptions to search within.
   *
   * @returns {EVA.Core.GetAvailableSubscriptionsResponse.SubscriptionDto | undefined} The loyalty subscription,
   *   if found; otherwise, undefined.
   */
  getLoyaltyProgram(
    subscriptions: EVA.Core.GetAvailableSubscriptionsResponse.SubscriptionDto[] | undefined
  ): EVA.Core.GetAvailableSubscriptionsResponse.SubscriptionDto | undefined {
    return (
      (subscriptions instanceof Array &&
        subscriptions?.find((sub) => sub.BackendID === Constants.LOYALTY_PROGRAM_BACKEND_ID)) ||
      undefined
    );
  },

  /**
   * Retrieves the loyalty balance for a user from their subscriptions.
   *
   * @param {EVA.Core.GetUserSubscriptionsResponse | undefined} userSubscriptions - The user's subscriptions response.
   *
   * @returns The user's loyalty balance, if found; otherwise, undefined.
   */
  getLoyaltyUserBalance(userSubscriptions: EVA.Core.GetUserSubscriptionsResponse | undefined) {
    return this.getLoyaltySubscription(userSubscriptions?.Subscriptions)?.Subscription?.LoyaltyBalance;
  },

  /**
   * Retrieves the user's loyalty tier name from their custom fields.
   *
   * @param {EVA.Core.LoggedInUserDto | undefined} user - The logged-in user object.
   *
   * @returns {string | undefined} The user's loyalty tier name,
   *   if found; otherwise, undefined.
   */
  getUserLoyaltyTierName(
    user: EVA.Core.LoggedInUserDto | undefined,
    configuration: EVA.Core.GetApplicationConfiguration
  ): string | undefined {
    if (!user) {
      return undefined;
    }

    return evaUtils.getCustomFieldEnumValueForResource(Constants.LOYALTY_PROGRAM_TIER_BACKEND_ID, user, configuration);
  },

  /**
   * Retrieves the user's loyalty tier expiration date from their custom fields.
   *
   * @param {EVA.Core.LoggedInUserDto | undefined} user - The logged-in user object.
   *
   * @returns {string | undefined} The user's loyalty tier expiration date,
   *   if found; otherwise, undefined.
   */
  getUserLoyaltyTierExpirationDate(
    user: EVA.Core.LoggedInUserDto | undefined,
    configuration: EVA.Core.GetApplicationConfiguration
  ): string | undefined {
    if (!user) {
      return undefined;
    }

    return evaUtils.getCustomFieldValueForResource(
      Constants.LOYALTY_PROGRAM_TIER_EXPIRATION_BACKEND_ID,
      user,
      configuration
    ) as string;
  },
};

export default loyaltyUtils;
