import { Entry } from "contentful";

import invariant from "./invariant";

export function assertEditorialType<T>(entry: Entry<any>, contentType: string | string[]): asserts entry is Entry<T> {
  const entryType = entry.sys.contentType.sys.id;

  if (Array.isArray(contentType)) {
    invariant(
      contentType.includes(entryType),
      `Expected editorial of type '${contentType.join(", ")}', got '${entry.sys.contentType.sys.id}'`
    );
  } else {
    invariant(
      entryType === contentType,
      `Expected editorial of type '${contentType}', got '${entry.sys.contentType.sys.id}'`
    );
  }
}

export function isSameEditorialTypeComponent(entry1: Entry<any>, entry2: Entry<any>) {
  return entry1?.sys?.contentType?.sys?.id === entry2?.sys?.contentType?.sys?.id;
}

/**
 * Sometimes when setting a child component in the "draft" state, the link reference to
 * the drafted component is still returned by the API. When we want to only filter active entries
 * we need to check its entry type.
 *
 * @param entry The entry to verify
 */
export function isActiveEntry(entry: Entry<any>) {
  return entry?.sys.type === "Entry" || entry?.sys.type === "Asset";
}
