import UAParser from "ua-parser-js";

type GlobalsContainer = Record<string, any>;

declare global {
  var kiko: GlobalsContainer;
}

function _getAppGlobalContainer(): GlobalsContainer {
  window.kiko = window.kiko ?? {};
  return window.kiko;
}

function windowExists() {
  return typeof window !== "undefined";
}

function setAppGlobal(key: string, value: any) {
  _getAppGlobalContainer()[key] = value;
}

function getAppGlobal(key: string, initValue?: any): any {
  let val = _getAppGlobalContainer()[key];
  if (!val && initValue) {
    val = initValue;
    setAppGlobal(key, val);
  }
  return val;
}

function getUserAgent(): string {
  return navigator.userAgent;
}

function getOS(): string | undefined {
  return new UAParser(getUserAgent()).getOS()?.name;
}

export const ClientUtils = { windowExists, setAppGlobal, getAppGlobal, getUserAgent, getOS };
