import classNames from "classnames";
import { DetailedHTMLProps, HTMLAttributes } from "react";

import styles from "./grid.module.scss";

type Props = {} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default function Grid(props: Props) {
  return <div {...props} className={classNames(styles.container, props.className)} />;
}
