import {
  KikFirebaseService,
  KikSocialSignInKeys,
  KikSocialSignInUxModes,
} from "@kikocosmeticsorg/uc-api-nest-common-fe";

import Logger from "~/services/logger/logger";

export default class Firebase extends KikFirebaseService {
  static get instance(): Firebase {
    if (!this._instance) {
      this.init(
        {
          apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
          appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
          authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
          projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
        },
        {
          enabledSocials:
            ((process.env.NEXT_PUBLIC_SOCIAL_LOGIN_ENABLED_SOCIALS || void 0)?.split(",") as KikSocialSignInKeys[]) ||
            [],
          logger: Logger.instance,
          popupFallsBackToRedirect: process.env.NEXT_PUBLIC_SOCIAL_LOGIN_POPUP_FALLBACK === "true",
          socialLoginUxMode:
            KikSocialSignInUxModes[process.env.NEXT_PUBLIC_SOCIAL_LOGIN_UXMODE as KikSocialSignInUxModes],
        }
      );
    }

    return this._instance!;
  }
}
