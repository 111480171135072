import Script from "next/script";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { GTMReadyState, sequentialLoadingState } from "~/atoms/sequentialLoading";
import * as TrackingScripts from "~/components/tracking/tracking-scripts";

export default function GTMIntegration() {
  // Recoil state that tells if the Didomi popup is currently opened or not
  const sequentialLoadingReady = useRecoilValue(GTMReadyState);
  const setGTMLoaded = useSetRecoilState(sequentialLoadingState);

  return sequentialLoadingReady ? (
    <>
      {process.env.NEXT_PUBLIC_TRACKING_GTM_ID && sequentialLoadingReady ? <TrackingScripts.gtmNoScript /> : null}
      {process.env.NEXT_PUBLIC_TRACKING_GTM_ID && sequentialLoadingReady ? (
        <Script id="gtm-init" onReady={() => setGTMLoaded((current) => ({ ...current, GTM: true }))}>
          {TrackingScripts.script}
        </Script>
      ) : null}
    </>
  ) : null;
}
