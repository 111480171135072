import { hooks, state } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { useQuery } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { createContext, useContext, useMemo, useState } from "react";

import { useAuthContext } from "~/contexts/auth";
import Constants from "~/utils/constants";
import crossCountryUtils from "~/utils/crossCountry-utils";
import evaUtils from "~/utils/eva-utils";

import { useConfiguration } from "./configuration";

type FavouriteStoreContext = {
  preferredStoreId?: number;
  preferredStore?: EVA.Core.GetOrganizationUnitSummaryResponse | null;
  setPreferredStoreId: (newId: number) => void;
};

const Context = createContext<FavouriteStoreContext>({ setPreferredStoreId: (newId) => "" });

export default function FavouriteStoreProvider(props: { children: React.ReactNode }) {
  const { isAuthenticated, user, refreshUserData } = useAuthContext();
  const router = useRouter();
  const { configuration } = useConfiguration();

  const updateUser = hooks.useCallService({ service: Core.UpdateUser });
  const getOrganizationUnitSummary = hooks.useCallService({ service: Core.GetOrganizationUnitSummary });
  const evaEndpointUrl = hooks.useGetState(state.core.evaEndpointUrlState);
  const currentCountry = crossCountryUtils.getCurrentCountryCode(router);

  const [updateLocalStorage, setUpdateLocalStorage] = useState(false);

  const getPreferredStoreFromUser = (user: EVA.Core.LoggedInUserDto) => {
    const value = evaUtils.getCustomFieldValueForResource(Constants.PREFERRED_COLLECT_ID, user, configuration);
    return typeof value === "number" ? value : undefined;
  };

  const getPreferredStoreFromStorage = (currentCountry: string) => {
    if (typeof window !== "undefined" && localStorage?.getItem(`${currentCountry}-selectedStore`)) {
      return Number(localStorage.getItem(`${currentCountry}-selectedStore`));
    } else {
      return;
    }
  };

  const preferredStoreId = useMemo(() => {
    // if authenticated fetch from eva user otherwise from local storage
    if (isAuthenticated) {
      return getPreferredStoreFromUser(user!);
    } else {
      return getPreferredStoreFromStorage(currentCountry);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountry, isAuthenticated, user, updateLocalStorage]);

  const setStoreInStorage = (storeID: number, currentCountry: string) => {
    if (typeof window !== "undefined") {
      localStorage.setItem(`${currentCountry}-selectedStore`, `${storeID}`);
      setUpdateLocalStorage(!updateLocalStorage);
      // setTimeout(() => setUpdateLocalStorage(!updateLocalStorage), 1000);
    }
  };

  const setPreferredStoreId = async (newId: number) => {
    const customFields: EVA.Core.CustomFieldKeyValue[] = [];
    const customFieldId = evaUtils.getCustomFieldConfiguration(
      Constants.PREFERRED_COLLECT_ID,
      configuration
    )?.CustomFieldID;

    if (customFieldId) {
      customFields.push({ CustomFieldID: customFieldId, NumberValue: newId });
    }

    if (isAuthenticated && user && customFields.length > 0) {
      await updateUser({ ID: user.ID, CustomFields: customFields });
      refreshUserData();
    } else {
      setStoreInStorage(newId, currentCountry);
    }
  };

  const preferredStoreQuery = useQuery({
    queryKey: ["preferredStore", preferredStoreId],
    staleTime: Infinity,
    keepPreviousData: true,
    queryFn: async () => {
      const data = await getOrganizationUnitSummary({
        OrganizationUnitID: preferredStoreId!,
      });

      if (!data) {
        return null;
      }

      return data;
    },
    enabled: !!evaEndpointUrl && !!preferredStoreId,
  });

  return (
    <Context.Provider
      value={{
        preferredStoreId,
        preferredStore: preferredStoreQuery.data,
        setPreferredStoreId,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

export function useFavouriteStore() {
  const context = useContext(Context);

  if (!context) {
    throw new Error(`useFavouriteStore must be used within a FavouriteStoreProvider`);
  }

  return context;
}
