import { useQuery } from "@tanstack/react-query";

import { useConfiguration } from "~/contexts/configuration";

/**
 * Retrieves root products detailed info for given line items products
 * @param lines The line items to retrieve products root data for
 * @returns An object containing color product keys and their parents data as values, if any
 */
export function useLinesProductsHierarchicalData(lines: EVA.Core.OrderLineDto[]): Record<string, any> | undefined {
  const products = lines
    .filter((line) => !!line.ProductID && !!line.Product)
    .map((line) => line.Product!.Properties)
    .filter(Boolean);
  return useProductsHierarchicalData(products);
}

/**
 * Retrieves root products detailed info for given products
 * @param products The product object to retrieve root data for
 * @returns An object containing color product keys and their parents data as values, if any
 */
export function useProductsHierarchicalData(products: any): Record<string, any> | undefined {
  let ids: { parentIds: string[]; productIds: string[] } = { parentIds: [], productIds: [] };
  let parentsToProductIds: Map<string, string> = new Map();
  products.forEach((p: any) => {
    const parentId = p.parent_product_ids?.[0];
    if (parentId) {
      ids.parentIds.push(parentId);
      ids.productIds.push(p.product_id);
      parentsToProductIds.set(`${parentId}-${p.product_id}`, p.product_id);
    }
  });

  const { eva } = useConfiguration();
  const { data } = useQuery({
    queryKey: ["linesProductsDetails", ids.parentIds.sort().join("|")],
    staleTime: Infinity,
    queryFn: async () =>
      await eva.getProductsByIds(ids.parentIds).then((r) => {
        let result: Record<string, any> = {};
        r.Products?.forEach((productParent) => {
          ids.productIds.forEach((productId) => {
            const resultKey = parentsToProductIds.get(`${productParent.product_id}-${productId}`);
            if (resultKey) {
              result[resultKey] = productParent;
            }
          });
        });
        return result;
      }),
    enabled: ids.parentIds.length > 0,
  });
  return ids.parentIds.length > 0 ? data : {};
}
