import { debounce } from "lodash";
import { createContext, useContext, useEffect, useState } from "react";

type windowSize = {
  width?: number;
  height?: number;
};

type breakpoint = "mobile" | "desktop" | undefined;

const Context = createContext<{
  breakpoint: breakpoint;
  windowSize: windowSize;
}>({ breakpoint: undefined, windowSize: { width: undefined, height: undefined } });

export default function BreakpointProvider(props: { children: React.ReactNode }) {
  const [breakpoint, setBreakpoint] = useState<breakpoint>();
  const [windowSize, setWindowSize] = useState<windowSize>({ height: undefined, width: undefined });

  useEffect(() => {
    const handleResize = debounce((entries) => {
      if (entries.length > 0) {
        setBreakpoint(window.innerWidth >= 900 ? "desktop" : "mobile");
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
    }, 200);

    const observer = new ResizeObserver(handleResize);
    observer.observe(document.body);
    checkScrollbarWidth();

    return () => {
      observer.disconnect();
    };
  }, []);

  return <Context.Provider value={{ breakpoint, windowSize }}>{props.children}</Context.Provider>;
}

function checkScrollbarWidth() {
  // check scrollbar width to avoid reflow on modals opening
  if (["complete", "interactive"].includes(document.readyState)) {
    applyScrollbarWidth();
  } else {
    let completedInterval = setInterval(() => {
      if (["complete", "interactive"].includes(document.readyState)) {
        // The page is fully loaded.
        applyScrollbarWidth();
        clearInterval(completedInterval);
        // completedInterval = undefined;
      } else {
        console.info("waiting for document.readyState completed");
      }
    }, 500);
  }
}

export function applyScrollbarWidth() {
  const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
  if (scrollBarWidth > 0) {
    document.body.style.setProperty("--scrollbar-width", scrollBarWidth + "px");
  } else {
    document.body.style.removeProperty("--scrollbar-width");
  }
}

export function useBreakpoint() {
  const context = useContext(Context);

  if (!context) {
    throw new Error(`useBreakpoint must be used within a BreakpointProvider`);
  }

  return context.breakpoint;
}

export function useWindowSize() {
  const context = useContext(Context);

  if (!context) {
    throw new Error(`useBreakpoint must be used within a BreakpointProvider`);
  }

  return context.windowSize;
}
