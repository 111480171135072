import classNames from "classnames";
import { Entry } from "contentful";
import { cloneElement, ImgHTMLAttributes, SVGProps } from "react";

import { useTextDir } from "~/contexts/text-dir";
import Editorials from "~/types/editorials";

import styles from "./icon.module.scss";
import IconAsset from "./icon-asset";
import iconList from "./icon-list";

// list of icons that must be horizontally flipped on RTL languages
// follow this guide for understanding when to mirror icons
// https://m2.material.io/design/usability/bidirectionality.html#mirroring-elements
const mirroringIconsOnRtl: Array<string> = [
  "arrow-left",
  "arrow-right",
  "chevron-left",
  "chevron-right",
  "star-25-filled",
  "star-half",
  "star-75-filled",
];

type Props = {
  name: keyof typeof iconList;
  svgMedia?: Entry<Editorials.Asset>;
  width?: string | number;
  height?: string | number;
  value?: number | string;
} & SVGProps<SVGSVGElement> &
  Omit<ImgHTMLAttributes<HTMLImageElement>, "placeholder">;

// The difference between Icon and Illustration components is that the icon component is wrapped within a <div />
// So the icon can be rendered along with a counter to display some data
// The Illustraion component instead is agnostic and does not have any container <div />
export default function Icon(props: Props) {
  const { name, value, svgMedia, ...rest } = props;

  const dir = useTextDir();
  const isIconRTL = dir === "rtl" && mirroringIconsOnRtl.includes(name);

  if (svgMedia) {
    return <IconAsset svgMedia={svgMedia} value={value} isIconRTL={isIconRTL} {...rest} />;
  }

  if (name in iconList) {
    return (
      <span className={classNames(styles.iconContainer, rest.className)}>
        {/* SVG element */}
        {cloneElement(iconList[name], {
          ...rest,
          className: classNames(isIconRTL ? styles.iconRtl : null),
        })}
        {/* Possible icon counter */}
        {typeof value === "number" || (typeof value === "string" && value.length > 0) ? (
          <span className={styles.iconValue}>{value}</span>
        ) : null}
      </span>
    );
  } else if (process.env.NODE_ENV === "development") {
    return cloneElement(iconList["close"], { ...rest, color: "red" });
  } else {
    return null;
  }
}
