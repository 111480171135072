import { atom } from "recoil";

export enum GlobalNotificationTypes {
  info = "info",
  error = "error",
  success = "success",
}

export type GlobalNotificationStateProps =
  | {
      open?: boolean;
      slug?: string;
      type?: GlobalNotificationTypes;
      linkCaption?: string;
      linkHref?: string;
      ctaCaption?: string;
      closeOnClickCta?: boolean;
      onClickCta?: () => void;
      title?: string;
      description?: string;
    }
  | undefined;

export const globalNotificationState = atom<GlobalNotificationStateProps>({
  key: "globalNotificationState",
  default: {},
});
