import classNames from "classnames";
import Image from "next/image";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";

import Container from "~/components/common/container";
import Grid from "~/components/common/grid";
import HighlightedText from "~/components/common/highlighted-text";
import appRoutes from "~/utils/app-routes";

import errorImage from "../../../public/backgrounds/checkout-error.jpg";
import MiniFooter from "../layout/mini-footer";
import buttonStyles from "./button.module.scss";
import styles from "./generic-error.module.scss";
import Icon from "./icon";

export default function GenericError(props: any) {
  const t = useTranslations();
  const router = useRouter();

  return (
    <>
      <header className={styles.header}>
        <a className={styles.center} href={"/" + router.locale + appRoutes.HOMEPAGE}>
          <Icon name="kiko" height={24} />
        </a>
      </header>
      <main className={styles.main}>
        <Container>
          <Grid className={styles.grid}>
            <div className={styles.column1}>
              <h1 className={styles.heading}>
                <HighlightedText text={t("generic.errorPage.title")} />
              </h1>
              {/* <p className={styles.description}>{t("generic.errorPage.description")}</p> */}
              <a
                className={classNames([buttonStyles.button, buttonStyles["secondary"], buttonStyles.linkButton])}
                href={"/" + router.locale + appRoutes.HOMEPAGE}
              >
                {t("generic.goToHomepage")}
              </a>
            </div>
            <div className={styles.column2}>
              <Image src={errorImage} alt="" />
            </div>
          </Grid>
          <div className={styles.container}>
            {t.rich("generic.customer_care", {
              gradient: (chunks) => {
                return <span className={styles.gradient}>{chunks?.toString()}</span>;
              },
              link: (chunks) => {
                return (
                  <a className={styles.link} href={"/" + router.locale + appRoutes.CUSTOMER_HELP_CENTER}>
                    {chunks?.toString()}
                  </a>
                );
              },
            })}
          </div>
        </Container>
      </main>
      <MiniFooter entry={props.footer} />
    </>
  );
}
