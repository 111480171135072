import classNames from "classnames";
import { Entry } from "contentful";
import { ImgHTMLAttributes, useRef } from "react";

import Editorials from "~/types/editorials";

import Icon from "./icon";
import styles from "./icon.module.scss";
import MediaAsset from "./media-asset";

type Props = {
  svgMedia: Entry<Editorials.Asset>;
  width?: string | number;
  height?: string | number;
  value?: number | string;
  className?: string;
  isIconRTL?: boolean;
} & Omit<ImgHTMLAttributes<HTMLImageElement>, "placeholder">;

export default function IconAsset(props: Props) {
  const { svgMedia, width = 20, height = 20, value, isIconRTL, className, ...rest } = props;

  const valueRef = useRef<HTMLSpanElement>(null);

  if (svgMedia) {
    return (
      <span
        className={classNames(styles.iconContainer, className)}
        style={{
          width: width,
          height: height,
          marginInlineEnd: valueRef.current
            ? valueRef.current.offsetWidth > 26
              ? 20
              : valueRef.current?.offsetWidth - 6
            : undefined,
        }}
      >
        <MediaAsset
          {...rest}
          entry={svgMedia}
          className={isIconRTL ? styles.iconRtl : undefined}
          width={width as number}
          height={height as number}
        />
        {/* Possible icon counter */}
        {typeof value === "number" || (typeof value === "string" && value.length > 0) ? (
          <span
            ref={valueRef}
            className={styles.iconValue}
            style={{
              marginInlineEnd: valueRef.current ? `${-valueRef.current.offsetWidth + 6}px` : 0,
              marginInlineStart: "auto",
            }}
          >
            {value}
          </span>
        ) : null}
      </span>
    );
  } else {
    return null;
  }
}
