type Props = {
  text: string;
  tag?: "span" | "strong";
};

export default function EscapeNewLine({ text, tag = "span" }: Props) {
  if (!text) return null;

  if (tag == "span") {
    return (
      <span
        key={`escapenewline-${text.substring(0, 5)}`}
        dangerouslySetInnerHTML={{ __html: text.replaceAll("\\n", "<br />") }}
      />
    );
  } else {
    return (
      <strong
        key={`escapenewline-${text.substring(0, 5)}`}
        dangerouslySetInnerHTML={{ __html: text.replaceAll("\\n", "<br />") }}
      />
    );
  }
}
