import classNames from "classnames";
import { DetailedHTMLProps, forwardRef, HTMLAttributes, PropsWithChildren } from "react";

import styles from "./pane.module.scss";

type Props = PropsWithChildren &
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    className?: string;
  };

export default forwardRef<HTMLDivElement, Props>(function Pane(props, ref) {
  return (
    <div {...props} ref={ref} className={classNames(styles.pane, props.className)}>
      {props.children}
    </div>
  );
});
