import { useRecoilState } from "recoil";

import {
  globalNotificationState,
  GlobalNotificationStateProps,
  GlobalNotificationTypes,
} from "~/atoms/globalNotification";

interface GlobalNotificationOpts {
  timeout?: number;
}

// Custom React hook for managing global notifications
/*
 * [TS]: TODO: THIS SHOULD HANDLE A QUEUE OF NOTIFICATIONS, THAT WOULD ALLOW TO SHOW MULTIPLE POPUPS AND MANAGE THEM INDIVIDUALLY
 *        WHILE THIS WAY ONE NOTIFICATION ALWAYS OVERWRITES ANOTHER
 */
export function useGlobalNotification() {
  // Retrieve and manage global notification state using Recoil
  const [notificationState, setNotificationState] =
    useRecoilState<GlobalNotificationStateProps>(globalNotificationState);

  // Function to create a new global notification with specified properties
  const newGlobalNotification = (props: GlobalNotificationStateProps, { timeout }: GlobalNotificationOpts = {}) => {
    setNotificationState({
      ...props,
      // Force defaults
      open: props?.open ?? true,
      slug: props?.slug ?? "generic",
      type: props?.type ?? GlobalNotificationTypes.info,
    });

    typeof timeout === "number" &&
      timeout > 0 &&
      setTimeout(() => {
        closeGlobalNotification();
      }, timeout);
  };

  // Function to create a new global notification of type Info
  const newInfoGlobalNotification = (
    props: Omit<GlobalNotificationStateProps, "open" | "type">,
    opts?: GlobalNotificationOpts
  ): void => {
    newGlobalNotification(
      {
        ...props,
        open: !0,
        type: GlobalNotificationTypes.info,
      },
      opts
    );
  };

  // Function to create a new global notification of type Error
  const newErrorGlobalNotification = (
    props: Omit<GlobalNotificationStateProps, "open" | "type">,
    opts?: GlobalNotificationOpts
  ): void => {
    newGlobalNotification(
      {
        ...props,
        open: !0,
        type: GlobalNotificationTypes.error,
      },
      opts
    );
  };

  // Function to create a new global notification of type Info
  const newSuccessGlobalNotification = (
    props: Omit<GlobalNotificationStateProps, "open" | "type">,
    opts?: GlobalNotificationOpts
  ): void => {
    newGlobalNotification(
      {
        ...props,
        open: !0,
        type: GlobalNotificationTypes.success,
      },
      opts
    );
  };

  // Function to close the currently displayed global notification
  const openGlobalNotification = () => {
    setNotificationState({ ...notificationState, open: true });
  };

  // Function to close the currently displayed global notification
  const closeGlobalNotification = () => {
    setNotificationState({ ...notificationState, open: false });
  };

  return {
    notificationState,
    setNotificationState,
    newGlobalNotification,
    newInfoGlobalNotification,
    newErrorGlobalNotification,
    newSuccessGlobalNotification,
    openGlobalNotification,
    closeGlobalNotification,
  };
}
