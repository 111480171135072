import { useLocale } from "next-intl";
import { createContext, useContext } from "react";

const Context = createContext<{ dir: "ltr" | "rtl" }>({ dir: "ltr" });

export default function TextDirProvider(props: { children: React.ReactNode }) {
  const locale = useLocale();
  const language = locale.split("-")[0];

  return <Context.Provider value={{ dir: language === "ar" ? "rtl" : "ltr" }}>{props.children}</Context.Provider>;
}

export function useTextDir() {
  const context = useContext(Context);

  if (!context) {
    throw new Error(`useTextDir must be used within a TextDirProvider`);
  }

  return context.dir;
}
