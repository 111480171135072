/* eslint-disable simple-import-sort/imports, prettier/prettier */
import {
  KikErrorCodes as ErrorCodes,
  KikErrorMessages as ErrorMessages,
  KikErrorResponse,
  KikGenericResponse as GenericResponse,
  KikGenericResponseBase as GenericResponseBase,
  remappedErrors,
  KikRestVerbs as RestVerbs,
} from "@kikocosmeticsorg/uc-api-nest-common-fe";
import "reflect-metadata";
import { HttpStatusCode } from "axios";
import { NextApiHandler, NextApiRequest, NextApiResponse } from "next";
import { UseFormSetError } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { Path } from "react-hook-form/dist/types/path/eager";
import { ZodError, ZodIssue } from "zod";
//
import { ApiConstants } from "~/shared/api/api-constants.class";
import { CommonUtils } from "~/utils/common-utils";
import Logger from "~/services/logger/logger";
import { ApiGenericError } from "~/shared/api/api-generic-error.class";

type ApiMethodHandlers = {
  [key in RestVerbs]?: NextApiHandler;
};

export class ApiUtils {
  // /{([a-zA-Z_$][a-zA-Z0-9_$]*)}/g
  private static _javascriptVariablePattern = "[a-zA-Z_$][a-zA-Z_$0-9]*";
  // Extract all params enclosed in curly braces in a path. E.g.: /api/v3/{foo}/{bar}
  private static _pathParamRegex = new RegExp(`\\{(${this._javascriptVariablePattern})\\}`, "g");

  static apiHandler(handler: ApiMethodHandlers, cors?: Function) {
    return async (req: NextApiRequest, res: NextApiResponse<GenericResponseBase>) => {
      try {
        // Custom function to manage cors: TODO we could prepare a default
        if (typeof cors === "function") {
          this.runCorsMiddleware(req, res, cors);
        }
        if (req.url?.includes(ApiConstants.privateBaseUrl)) {
          this.runBasicAuthMiddleware(req, res);
        }
        const method = (req.method || RestVerbs.GET).toUpperCase() as keyof ApiMethodHandlers;
        // check if handler supports current HTTP method
        if (typeof handler[method] !== "function") {
          throw KikErrorResponse.build(ErrorMessages.METHOD_NOT_ALLOWED, ErrorCodes.METHOD_NOT_ALLOWED, 405);
        }
        Logger.instance.info("ApiUtils: preliminary checks passed, running handler");
        // call method handler
        const responseData: any = await handler[method]!(req, res);
        /**
         * If we returned something it means we let this handle everything
         * otherwise it means we returned the response already
         */
        if (CommonUtils.isNotEmpty(responseData)) {
          res.status(200).json({
            ...(responseData instanceof GenericResponse
              ? responseData.serialize()
              : new GenericResponse(void 0, responseData).serialize()),
          });
        }
      } catch (err) {
        // global error handler
        Logger.instance.error("ApiUtils: API error caught", err);
        this.errorHandler(err, req, res);
      }
    };
  }

  static credentialsToBasicAuth(user: string, password: string): string {
    return `Basic ${Buffer.from(`${user}:${password}`).toString("base64")}`;
  }

  static errorFactory<T extends ApiGenericError>(e: T | KikErrorResponse): KikErrorResponse {
    if (e instanceof KikErrorResponse) {
      return e;
    }
    const remappedError = remappedErrors[`${e?.code}`];

    return KikErrorResponse.build.apply(null, [
      remappedError?.code,
      ErrorCodes[remappedError?.code],
      remappedError?.httpStatus || HttpStatusCode.InternalServerError,
      e,
    ]);
  }

  static async errorHandler(err: unknown, req: NextApiRequest, res: NextApiResponse): Promise<void> {
    let errorResponse;
    if (err instanceof KikErrorResponse) {
      errorResponse = err;
    } else if (err instanceof ZodError) {
      // Handle zod validation errors
      errorResponse = KikErrorResponse.build(
        ErrorMessages.BAD_REQUEST,
        ErrorCodes.BAD_REQUEST,
        HttpStatusCode.BadRequest,
        err
      );
    } else {
      errorResponse = new KikErrorResponse();
    }

    return res.status(errorResponse.status || 500).json(errorResponse.serialize());
  }

  static fromBearer(header: string = ""): string {
    return header.split(" ")[1];
  }

  static getCountryFromReferer(referer: string): string | void {
    return this.getLocaleFromReferer(referer)?.split("-")[0]?.toUpperCase();
  }

  static getLanguageFromReferer(referer: string): string | void {
    return this.getLocaleFromReferer(referer)?.split("-")[1];
  }

  static getLocaleFromReferer(referer: string): string | void {
    return referer?.match(/\/([A-Z]{2}-[A-Z]{2})\//i)?.[1];
  }

  static interpolateParams(urlTemplate: string, params: Record<string, string | number | boolean>): string {
    try {
      return urlTemplate.replace(this._pathParamRegex, (match, key) => {
        const value = params[key];
        return value !== void 0 ? encodeURIComponent(value) : match;
      });
    } catch (e) {
      Logger.instance.warn("ApiUtils: couldn't interpolate params", e);
    }

    return urlTemplate;
  }

  static mapBackendErrorsToForm<T extends FieldValues>(
    authError: KikErrorResponse | null,
    setError: UseFormSetError<T>
  ): void {
    if (authError) {
      // Zod validation from backend
      if (authError.errorCode === 801 && Array.isArray(authError.data?.issues)) {
        authError.data.issues.forEach((issue: ZodIssue) => {
          setError(`${issue.path[0]}` as Path<T>, {
            type: "validate",
            message: issue.message,
          });
        });
      } else {
        setError("root.serverError", {
          type: `${authError.errorCode}`,
        });
      }
    }
  }

  static runBasicAuthMiddleware<T = any>(req: NextApiRequest, res: NextApiResponse): void {
    const authHeader = req.headers.authorization || "";
    // check for basic auth header
    if (authHeader.indexOf("Basic ") === -1) {
      this._handleBadCredentials(res);
    }

    // verify auth credentials
    const base64Credentials = authHeader.split(" ")[1];
    const credentials = Buffer.from(base64Credentials, "base64").toString("ascii");
    // TODO: THIS IS A BIT RUDIMENTARY, CAN WE IMPROVE?
    if (!process.env.API_USERS!.split(",").includes(credentials)) {
      this._handleBadCredentials(res);
    }
  }

  static runCorsMiddleware<T = any>(req: NextApiRequest, res: NextApiResponse, fn: Function): Promise<T | Error> {
    return new Promise((resolve, reject) => {
      fn(req, res, (result: any) => {
        if (result instanceof Error) {
          return reject(KikErrorResponse.build(result.message));
        }

        return resolve(result as T);
      });
    });
  }

  private static _handleBadCredentials(res: NextApiResponse): void {
    res.setHeader("www-authenticate", `Basic realm="vercel"`);
    throw KikErrorResponse.build(ErrorMessages.BAD_CREDENTIALS, ErrorCodes.BAD_CREDENTIALS, 401);
  }
}
