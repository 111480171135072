import React, { ReactNode } from "react";

import { AuthContext } from "~/contexts/auth";
import useAuth from "~/hooks/use-auth";

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const auth = useAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
